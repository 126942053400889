// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-page-js": () => import("/opt/build/repo/src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-examples-js": () => import("/opt/build/repo/src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("/opt/build/repo/src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-guayabo-android-js": () => import("/opt/build/repo/src/pages/guayabo/android.js" /* webpackChunkName: "component---src-pages-guayabo-android-js" */),
  "component---src-pages-guayabo-index-js": () => import("/opt/build/repo/src/pages/guayabo/index.js" /* webpackChunkName: "component---src-pages-guayabo-index-js" */),
  "component---src-pages-guayabo-ios-js": () => import("/opt/build/repo/src/pages/guayabo/ios.js" /* webpackChunkName: "component---src-pages-guayabo-ios-js" */),
  "component---src-pages-guayabo-privacy-js": () => import("/opt/build/repo/src/pages/guayabo/privacy.js" /* webpackChunkName: "component---src-pages-guayabo-privacy-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-old-index-js": () => import("/opt/build/repo/src/pages/oldIndex.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

